.ProviderSectionMargin {
  margin: 77px;
}

.ProviderSectionLeftRightMargin {
  margin: 0 77px 0 77px;
}

.ProviderHeaderRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.ProviderHeaderTitle {
  flex: 1;
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-size: 40px;
  text-align: left;
}

a.ProviderHeaderLink {
  font-weight: 300;
  color: black;
  text-decoration: none;
}

.ProviderHeaderReviews {
  flex: 1;
  text-align: start;
}

.ProviderDescriptionRow {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.ProviderDescriptionLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  text-align: left;
}

.ProviderDescriptionRight {
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-height: 540px;
}

.ProviderDescriptionImage {
  flex: 1;
  object-fit: cover;
  max-height: 125%;
  max-width: 125%;
  object-position: center;
}

.ProviderHeaderReviews a {
  color: #000;
}

.ServicesSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ServiceCategoryContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.ChosenServiceCategory {
  background-color: black;
  color: white;
}

.ServiceContainer {
  align-items: center;
  margin-top: 45px;
  gap: 50px;
  font-family: 'Nunito', sans-serif;
}

.BookServiceButton {
  background-color: white;
  color: black;
  border: 1px solid #000;
  padding: 15px 50px 15px 50px;
  cursor: pointer;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}

.BookServiceButton:hover {
  background-color: #423E3A;
  color: white;
  cursor: pointer;
}

.ProviderBioSection {
  flex: 1;
  padding-left: 41px;
  text-align: start;
}

.ProviderBioSection p {
  font-family: 'Nunito', sans-serif;
}

.ProviderBioSection a {
  color: #fff;
}

.ProviderDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.ProviderDetailsImage {
  margin: 30px;
  height: 68px;
}

.ProviderDetailsHeader {
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
}

.ProviderDetailsText {
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  max-width: 380px;
}

.ProviderDetailsText a {
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  max-width: 380px;
  color: white;
}

.ProviderReviewContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 50px;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: center;
}

.ProviderReview {
  display: flex;
  flex-direction: column;
  width: 520px;
  font-family: 'Nunito', sans-serif;
  gap: 12px;
  text-align: left;
}

.ReviewerImage {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}


.carousel-container {
  padding-bottom: 30px;
  justify-content: center;
}

.carousel-item {
  padding: 20px;
  min-height: 500px;
  display: flex;
}

.carousel-dot-list {

}

.SmallBio {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: black;
  padding: 30px;
}

.SmallBioContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  max-width: 340px;

}

.SmallBioImageMask {
  height: 180px;
  width: 180px;
  display: flex;
  overflow: hidden;
  border-radius: 90px;
}

.SmallBioImage {
  flex: 1;
  object-fit: cover;
  object-position: 50% 20%;
  max-width: 100%;
  max-height: 100%;
}

.SmallBioName {
  font-weight: bold;
}

.SmallBioName a {
  color: black;
}

.SmallBioBio {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 150px;
  overflow-y: auto;
}

.ProviderLocationSection {
  display: flex;
  flex-direction: column;
}

.FooterLocationContainer {
  display: flex;
  flex-wrap: wrap;
}

.FooterLocation {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
  font-family: 'Nunito', sans-serif;
  gap: 5px;
}

.FooterLocation h3 {
  margin-top: 50px;
}

.FooterLocation a {
  color: white;
}

