
/** 
 * This flexes to fill its container so always size it by putting it in an
 * appropriately sized parent element.
 */
.EmailSignupBox {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}

.EmailSignupBoxGap20 {
  gap: 20px;
}

.EmailSignupText {
  width: 100%;
  height: 65px;
  padding: 0;
  margin: 0;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid black;
  text-align: center;

  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  box-sizing: border-box;
}

.EmailSignupButton {
  width: 252px;
  padding-left: 24px;
  padding-right: 25px;
  height: 65px;
  margin: 0;

  font-family: 'Nunito', sans-serif;
  font-size: 18px;

  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid black;
  border-block-end-width: 0;

  /* ios safari does funny things rounding buttons! */
  -webkit-appearance: none;
  
  font-weight: bold;
  background-color: #000000;
  color: #ffffff;
}

@media (max-width: 800px) {
  .EmailSignupBox {
    flex-direction: column;
  }

  .EmailSignupText {
    border-radius: 8px;
  }
  
  .EmailSignupButton {
    border-radius: 8px;
  }
}

/* these get used when forcing the email signup to always be stacked */
.EmailSignupBoxStacked {
  flex-direction: column;
}

.EmailSignupTextStacked {
  border-radius: 8px;
}

.EmailSignupButtonStacked {
  border-radius: 8px;
}
