.AccountSettingsPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  gap: 50px;
  align-items: stretch;
}

.AccountSettingsBox {
  display: flex;
  flex: 1;
  text-align: start;
  flex-direction: column;
  background-color: white;
  border: 1px solid #423E3A;
  border-radius: 5px;
  padding: 30px;
}

.AccountSettingsBox h3 {
  font-size: 18px;
  color: #96695e;
  font-weight: bold;
  margin-block: .5em;
}