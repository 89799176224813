.MapSearchPage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.MapSearchHeader {
  border-bottom: 1px solid #423e3a80;
}

.MapBodySection {
  flex: 1;
  overflow: hidden;
  align-items: stretch;
}

.MapSearchOption {
  display: flex;
  align-items: center;
  gap: 10px;
}

.MapSearchOptions {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px 10px 20px;
  gap: 20px;
}

.MapSearchBody {
  flex: 1;
}

.MapSearchBottom {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.MapSearchLeft {
  flex: 1;
  min-width: 400px;
  overflow-y: auto;
}

.MapSearchRight {
  flex: 2;
}

.MapSearchResult {
  margin: 10px;
  padding: 20px;
  width: 410px;
  max-width: 90%;
  gap: 10px;
  background-color: white;
  border: 1px solid #96695e;
  border-radius: 8px;
  display: flex;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  flex-direction: column;
  align-items: start;
  text-align: start;
  color: black;
  text-decoration: underline;
  text-decoration-color: white;
}

a .MapSearchResult div {
  text-decoration: none;
}

.MapSearchResultTopRow {
  display: flex;
  gap: 10px;
}

.MapSearchResultImageContainer {
  flex: 1;
  height: 120px;
}

.MapSearchResultImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MapSearchResultInfo {
  flex: 2;
}

.MapAskForLocation {
  flex: 1;
  height: 100%;
  display: flex;
  padding: 20px;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.MapSearchLocations {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.MapChecklist {
  display: flex;
  flex-direction: column;
}

.MapChecklistItem {
  display: flex;
  gap: 10px;
}

.MapSearchOverlayContainer {
  position: absolute;
  flex: 1;
  width: 100%;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MapSearchOverlayButton {
  font-weight: bold;
  padding: 10px 45px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.MapSearchResultBottomRow {
  width: 100%;
}

.MapSearchResultButton {
  background-color: #96695e;
  color: white;
  width: 100%;
  border-radius: 5px;
  padding: 10px 54px 10px 54px;
  border: 1px solid #96695e;
  cursor: pointer;
  font-weight: bold;
}

.MapMarkerPopup {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.MapMarkerPopupBusinessName a {
  color: black;
}

.MapMarkerPopupReviewsRow a {
  color: black;
  text-decoration: none;
}

.MapMarkerPopupImageContainer {
  width: 180px;
  height: 130px;
}

.MapMarkerPopupImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}