.AppointmentsPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  gap: 50px;
  align-items: stretch;
}

.AppointmentsBox {
  display: flex;
  text-align: start;
  flex-direction: column;
  background-color: white;
  border: 1px solid #423E3A;
  border-radius: 5px;
  padding: 30px;
}

.AppointmentsBoxHeader {
  font-size: 18px;
  color: #96695e;
  font-weight: bold;
}

.AppointmentsItemsList {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.AppointmentsItem {
  padding: 15px 0 15px 0;
  display: flex;
  gap: 5px;
}

.AppointmentsItemNoneFound {
  flex-direction: column;
  gap: 25px;
}

.AppointmentsItemLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.AppointmentsItemLeft h2 {
  margin: 0;
}

.AppointmentsItemCenter {
  flex: 6;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: start;
  justify-content: start;
  text-align: start;
}

.AppointmentsItemRight {
  flex: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: start;
  justify-content: start;
  text-align: start;
}

.AppointmentsItem + .AppointmentsItem {
  border-top: 1px solid #423E3A;
}

.AppointmentsToolArea {
  flex: 1;
}
