/* generic element styles across pages */

body {
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
}

h1 {
  font-family: 'Sorts Mill Goudy', serif;
  font-weight: normal;
  font-size: 40px;
}

h2 {
  font-family: 'Sorts Mill Goudy', serif;
  font-weight: normal;
  font-size: 34px;
}

@media (max-width: 600px) {
  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 30px;
  }
}

/*
standard action button. this is black in light color schemes 
it should be applied to 'button' elements. it flexes to fill 100% of
the space of the parent.
*/
.ActionButton {
  flex: 1;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 24px 10px 24px;
}

.ExtraPadding {
  padding: 10px 54px 10px 54px;
}

.CategoryButton {
  flex: 1;
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
  background-color: #96695e;
  height: 70px;
  color: white;
  padding: 10px 54px 10px 54px;
  border: 1px solid #96695e;
  cursor: pointer;
}

.CategoryButtonInverted {
  background-color: white;
  color: #96695e;
}

.Inverted {
  background-color: white;
  color: black;
}

.Inverted:disabled {
  color: gray;
  cursor: initial;
}

.Borderless {
  border: none;
}

.BrownBg {
  background-color: #96695e;
}

.BrownText {
  color: #96695e;
}

.BrownText a {
  color: #96695e;
  text-decoration: underline;
}

.DarkGrayText {
  color: #423e3a;
}

.DarkGrayText a {
  color: #423e3a;
  text-decoration: underline;
}

/* italicized gray font underneath inputs */
.InputHelpText {
  font-size: 16px;
  font-style: italic;
  color: #423E3A;
}

/* Used for RPC errors typically */
.ErrorText {
  color: #ea4b03;
}
