/* "The first main section under the header, "Welcome to a Healthier you" */
.WaitlistWelcomeSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px;
}

.WaitlistWelcomeSection h1 {
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 64px;
  font-family: 'Sorts Mill Goudy', sans-serif;
}

.WaitlistWelcomeSection h2 {
  font-size: 22px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
}

@media (max-width: 600px) {

  .WaitlistWelcomeSection {
    padding: 40px;
  }

  .WaitlistWelcomeSection h1 {
    font-size: 48px;
  }

  .WaitlistWelcomeSection h2 {
    font-size: 20px;
  }
}
