/**
 this surrounds everything, both the home page stuff and the provider dash.
 it does not scroll by default, because we want the kenko header to stay
 on the page, and also on the provider dashboard only individual sections
 scroll. everything in the app must implement its own scrolling!
*/
.App2 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start; /* keep the headers up top! */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/**
 * The scrolling main section below the sticky header. This contains
 * the footer too.
 */
.App2Body {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

/**
 * The scrolling main section below the sticky header. This contains
 * the footer too.
 */
.App2BodyNoScroll {
  flex: 1;
  overflow: hidden;
}

/**
 * By default, children get vertical flex? 
 */
.App2BodyVerticalFlex {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}