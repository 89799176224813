
/* "The first main section under the header, "Welcome to a Healthier you" */
.WelcomeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: start;
  gap: 10px;
  padding: 30px;
}

.WelcomeSection h1 {
  margin-bottom: 5px;
}

.WelcomeSectionOptions {
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}


@media (max-width: 660px) {
  .WelcomeSectionOptions {
    grid-template-columns: 1fr;
  }
}

.WelcomeSectionOption {
  flex: 1;
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  min-width: 300px;
  background-color: white;
  padding: 25px;
  color: #96695e;
  font-weight: bold;
  border-radius: 5px;
}

.WelcomeSectionOptionIcon {
  height: 30px;
  width: 30px;
}

.RecommendedProvidersSection {
  flex: 1;
  padding: 30px;
}

.MoreRecommendedProvidersSection {
  flex: 1;
  padding: 30px;
  max-width: 600px;
  margin-top: 40px;
  margin-bottom: 70px;
}

.MoreRecommendedProvidersSection h1 {
  line-height: 1.2em;
}