* {
  box-sizing: border-box;
}

body {
  background-color: #F6F4F1;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
}


/*@media (prefers-color-scheme: dark) {*/
  /*body {*/
  /*  background-color: #423E3A*/
  /*}*/
/*}*/

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* This is a generic section with 100% width so the background color can be
   edge to edge but the content is limited by the AppContentWidth below. */
.AppSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ProviderHeader {
  position: sticky;
  padding: 10px 77px 10px 77px;
  top: 0;
  z-index: 11;
  border-bottom: 1px solid black;
}

/*
 * This is the sticky border between the header and the page content.
 * The border position has to change as the scroll happens so it's
 * attached here and not to the main header
 */
.ProviderBottomBorder {
  border-bottom: 1px solid black;
  position: sticky;
  z-index: 10;
  top: 40px;
}

.AppSectionContent {
  max-width: min(1366px, 100%);
}

.HorizontalFlexBox {
  display: flex;
  flex-direction: row;
}

.VerticalFlexBox {
  display: flex;
  flex-direction: column;
}

.Flex1 {
  flex: 1;
}

.BeigeSection {
  background-color: #F6F4F1;
  color: black;
}

.WhiteSection {
  background-color: #FFF;
  color: black;
}

.RedSection {
  background-color: #96695e;
  color: white;
}

.LightRedSection {
  background-color: #cb8e7e;
  color: black;
}

.DraftWarningSection {
  background-color: #eb8e7e;
}

.DraftWarning {
  color: white;
  padding: 24px;
}

/*@media (prefers-color-scheme: dark) {*/
  /*.BeigeSection {*/
  /*  background-color: #423E3A;*/
  /*  color: white;*/
  /*}*/
  /**/
  /*.BeigeSection a {*/
  /*  color: white;*/
  /*}*/
/*}*/

.DarkSection {
  background-color: #423E3A;
  color: white;
}

.BlackSection {
  background-color: #000;
  color: white;
}

/*@media (prefers-color-scheme: dark) {*/
/*  .DarkSection {*/
/*    background-color: #000;*/
/*    color: white;*/
/*  }*/
/*}*/

.DarkSection a {
  color: white;
}

.Header {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 42px;
}

.WaitlistHeader {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #423E3A80;
}

.HeaderLeftElement {
  display: flex;
  flex-direction: row;
  justify-items: start;
  justify-content: start;
  align-items: center;
  font-size: 32px;
  padding-left: 8px;
  gap: 5px;
  font-weight: 200;
  font-family: 'Inter', sans-serif;
  flex-grow: 2;
}

.HeaderLogo {
  width: min(160px, 25vw);
}

/*@media (prefers-color-scheme: dark) {*/
/*  .HeaderLeftElement {*/
/*    filter: invert(100%);*/
/*  }*/
/*}*/

.HeaderRightElement {
  flex-grow: 0;
  margin: 0;
  padding: 0;
}

.ProfileButton {
  width: 46px;
  height: 46px;
}

.StandardButton {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.CalloutButton {
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
  font-weight: bold;
  background: black;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 8px;
  padding: 12px 36px 12px 36px;
}

/* PlainTextBox shows up in the "What is Kenko?" section and the founders'
   "From our healing journey to yours on the homepage. In both places it's
   next to an image. */
.PlainTextBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: start;
  /* 120 all around but accounting for padding on h2 (47px) and p (24px) */
  padding: 73px 120px 96px 120px;
}

.PlainTextBox h1 {
  margin-bottom: 0;
}

@media (max-width: 1000px) {
  .PlainTextBox {
    padding: 40px;
  }

  .PlainTextBox h1 {
    font-weight: normal;
    font-size: 28pt;
    margin-top: 0;
  }
}


.WhatIsKenkoSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  font-family: 'Nunito', sans-serif;
}

.WhatIsKenkoRight {
  flex: 1;
  /*min-width: 25vh;*/
  min-height: 600px;
  display: flex;
  justify-content: center;
  background-image: url("../images/home_splash_2.png");
  background-position: 25% 100%;
}

@media (max-width: 800px) {
  .WhatIsKenkoSection {
    flex-direction: column-reverse;
  }

  .WhatIsKenkoRight {
    min-height: 40vh;
    background-size: 100%;
    background-position: 25% 90%;
  }
}


.CategoriesSection {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 109px;
  margin-bottom: 109px;
}

.CategoryIcon {
  width: 277px;
  height: 263px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-family: 'Nunito', sans-serif;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.FoundersSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  font-family: 'Nunito', sans-serif;
}

.FoundersSectionLeft {
  flex: 1;
  min-width: 400px;
  min-height: 600px;
  display: flex;
  justify-content: center;
  background-image: url("../images/home_founders.jpeg");
  background-position: 50% 65%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 800px) {
  .FoundersSectionLeft {
    min-height: 60vh;
    background-position: 25% 50%;
  }
}

.FoundersImage {
  width: 45vw;
  align-self: center;
}

@media (max-width: 1000px) {
  .FoundersImage {
    width: 100vw;
  }
}

.ArticlePreviewSection {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

/* lays the articles in a row for wide layouts, column for narrow */
.ArticlePreviewsContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 80px;
}

.ArticlePreview {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-weight: normal;
}

.ArticlePreviewCaption {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.ArticlePreviewImageContainer {
  flex: 1;
  margin-bottom: 20px;
}

.ArticlePreviewImage {
  width: 100%;
  height: 280px;
  object-fit: cover;
}


@media (max-width: 1000px) {
  .ArticlePreviewsContainer {
    flex-direction: column;

  }

  .ArticlePreview {
    max-width: 1000px;
    flex-direction: row;
    align-items: start;
  }

  .ArticlePreviewCaption {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .ArticlePreviewCaption p {
    margin-top: 0;
  }

  .ArticlePreviewImage {
    width: 40vw;
    height: 230px;
  }
}

@media (max-width: 500px) {
  .ArticlePreviewsContainer {
    margin: 0;
  }

  .ArticlePreview {
    font-family: 'Sorts Mill Goudy', sans-serif;
  }
}

a.ArticlePreview {
  color: black;
}

/*@media (prefers-color-scheme: dark) {*/
/*  a.ArticlePreview {*/
/*    color: white;*/
/*  }*/
/*}*/

.ArticlePreviewTinyText {
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}

a.ArticlePreview {
  gap: 25px;
  text-decoration: none;
}

.ArticlePreviewText {
  text-decoration: none;
  font-size: 18px;
  margin-top: 5px;
}

.Footer {
  margin: 50px;
  min-height: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.FooterTopRow {
  flex: 1;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.FooterLogo {
  width: min(180px, 25vw);
  filter: invert(100%);
}

.FooterLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.FooterMiddleRow {
  display: flex;
  flex-direction: row;
  font-family: 'Nunito', sans-serif;
  margin-top: 25px;
  margin-bottom: 25px;
  min-width: 300px;
  justify-content: end;
}

.FooterRightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: start;
}

.FooterRightColumn a {
  color: white;
}

.FooterBottomRow {
  flex: 1;
  align-self: start;
  margin-top: 25px;
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
  color: white;
  text-align: start;
}

@media (max-width: 600px) {
  .FooterMiddleRow {
    flex-direction: column;
    gap: 30px;
  }
}

.ChatPage {
  flex: 1;
  display: flex;
  flex-direction: row;
  font-family: 'Nunito', sans-serif;
}

.ChatPageLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 40px;
  gap: 10px;
}

.ChatPageRight {
  flex: 2;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 500px;
}

.ChatPageInput {
  align-self: center;
  height: 40px;
  width: 400px;
  text-align: center;
  margin-bottom: 10px;
}

.NewConversationButton {
  width: 294px;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border-radius: 8px;
  margin-bottom: 30px;
}

.ChatRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 37px;
  padding: 5px 0 5px 0;
  align-items: start;
}

.ChatRowUser {
  justify-content: end;
}

.ChatInitial {
  height: 54px;
  width: 54px;
  margin-top: 10px;
  border: 1px solid black;
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChatInitialUser {
  background-color: #423E3A;
  color: white;
}

.ChatMessage {
  border-radius: 8px;
  max-width: 70%;
  text-align: start;
  padding-left: 30px;
  padding-right: 30px;
}

.ChatMessageAssistant {
  align-self: start;
}

.ChatMessageUser {
  background-color: #423E3A;
  color: white;
  align-self: end;
}

.InvertedColors {
  background-color: black;
  color: white;
  padding: 5px 10px 5px 10px;
}

.ChatMarkdown a {
  color: black;
}

.AboutKenkoSection {
  margin: 70px;
  max-width: 783px;
}

.AboutKenkoSection h2 {
  font-family: 'Sorts Mill Goudy', sans-serif;
}

.DisclaimerSection {
  margin: 40px;
  max-width: 1090px;
  text-align: start;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-style: italic;
}

.SocialLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.PrivacyPage {
  padding: 40px;
  text-align: left;
}

.PrivacyPage h2 {
  margin-top: 40px;
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-weight: normal;
}

.TransformFutureHealthEmailContainer {
  width: min(80vw, 760px);
  margin: 50px;
}