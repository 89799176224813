.DashboardPage {
  flex: 1;
  display: flex;
  flex-direction: row;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
}

.DashboardNav {
  display: flex;
  padding: 30px;
  gap: 10px;
  flex-direction: column;
  border-right: 1px solid #423e3a80;
}

.DashboardNavItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.DashboardNavItem a {
  color: black;
  display: flex;
  text-decoration: none;
}

.DashboardNavIcon {
  width: 30px;
  height: 30px;
}

/** the left nav switches to a top nav on small screens, and displayed in a row */
@media (max-width: 800px) {
  .DashboardPage {
    flex-direction: column;
  }
  
  .DashboardNav {
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-right: none;
    border-bottom: 1px solid #423e3a80;
  }
  
  .DashboardNavIcon {
    display: none;
  }
}

