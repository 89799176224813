.ResourceLibraryWhiteSection {
  max-width: 550px;
  padding-bottom: 50px;
}

.ResourceLibrarySticky {
  position: sticky;
  top: 0;
  z-index: 5;
}

.ResourceLibrarySearchBar {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 10px;
}

.ResourceLibraryInput {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  padding: 5px;
}

.ResourceLibraryContentSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.LibraryArticlePreview {
  flex: 1;
  background-color: #f6f4f1;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  max-width: 300px;
  height: 400px;
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-weight: normal;
}

.LibraryArticlePreviewCaption {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.LibraryArticlePreviewImageContainer {
  width: 100%;
  height: 55%;
}

.LibraryArticlePreviewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .ResourceLibraryContentSection {
    flex-direction: column;
  }

  .LibraryArticlePreview {
    display: flex;
    align-items: center;
    max-width: none;
    min-height: 200px;
    max-height: 200px;
    width: 100%;
    height: 200px;
    flex-direction: row;
  }

  .LibraryArticlePreviewImageContainer {
    height: 200px;
    width: 50%;
  }
  
  .LibraryArticlePreviewCaption {
    width: 50%;
  }
}

a.LibraryArticlePreview {
  color: black;
  text-decoration: none;
}

.LibraryArticlePreviewTinyText {
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}

.LibraryArticlePreviewText {
  text-decoration: none;
  font-size: 18px;
  margin-top: 5px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.LibraryPageControl {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 10px;
}

.LibraryTextSearch {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  padding: 8px 10px;
  border: none;
  border-radius: 4px; /* matches the dropdowns */
  text-align: center;
}

.PagerPageNumberCurrent {
  background-color: white;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.PagerPageNumber {
  background-color: white;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: gray;
}